@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  background-color: #000300;
}

.bg-hero-image {
  background-image: url('assets/bg2.png');
}

#dropdown-navbar-id {
  z-index: 1000; /* Set the z-index for the navbar menu */
}

.glide {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s, transform 0.5s;
}

.glide.visible {
  opacity: 1;
  transform: translateX(0);
}

.censored-tm {
  user-select: none; /* Prevent text selection for elements with class "censored-tm" */
}


.gradient-background {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  animation: gradient-animation 5s linear infinite;
  height: 100%; /* Keep the measurements of the height */
}

@keyframes gradient-animation {
  0% {
    --tw-gradient-stops: rgba(245, 101, 101, 1), rgba(245, 101, 101, 1); /* bg-red-500 */
  }
  100% {
    --tw-gradient-stops: rgba(155, 44, 44, 1), rgba(155, 44, 44, 1); /* bg-red-900 */
  }
}

